import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ColorPickerModule } from 'ngx-color-picker';
import { CaseResultsPerceptionComponent } from './case-results-perception.component';

@NgModule({
  declarations: [CaseResultsPerceptionComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ColorPickerModule,
  ],
  exports: [CaseResultsPerceptionComponent],
})
export class CaseResultsPerceptionModule {}
