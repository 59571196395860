import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

import { AceEditorComponent } from './ace-editor.component';

@NgModule({
  declarations: [AceEditorComponent],
  imports: [CommonModule, MatButtonModule, FlexLayoutModule],
  exports: [AceEditorComponent],
})
export class AceEditorModule {}
